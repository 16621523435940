import React, { useState } from 'react';
import { Form } from '../form/Form';
import { Card } from '../card/card';
import { cardContent } from '../card/cardContent';

import { Footer } from '../footer/fotter';
import { Header } from '../header/header';
import { Slider } from '../slider/slider';
import image1 from '../../assets/images/images8.jpeg';
import image2 from '../../assets/images/images9.jpeg';
import image4 from '../../assets/images/images10.jpeg';
import image3 from '../../assets/images/images12.jpeg';
import { Freelance } from '../freelance/freelance';

import './main.css';

export const Main = () => {
  const [current, setCurrent] = useState(0);

  return (
    <div className='main-container'>
      <div className='form-header-container'>
        <Header />
        <Form />
      </div>
      <div className='slider-image-container'>
        <div className='slider-image'>
          <img className='one' src={image1} alt='' />
          <img className='two' src={image2} alt='' />
          <img className='three' src={image3} alt='' />
          <img className='four' src={image4} alt='' />
        </div>
        <div className='slogan-container'>
          <div>We</div>
          <div>Helps</div>
          <div style={{ color: '#e21880' }}>to</div>
          <div>Build</div>
          <div>Design.</div>
        </div>
        <div>
          <div className='card-footer-container-mobile'>
            {cardContent.map(({ title, content }) => (
              <Card key={title} title={title} paragraph={content} />
            ))}
          </div>
          <div className='card-footer-container-desktop'>
            <Slider
              currentContent={current}
              handleClickNext={() => setCurrent(current + 1)}
              handleClickPrev={() => setCurrent(current - 1)}
              preButtonDisabled={current === 0}
              nextButtonDisabled={current === cardContent.length - 1}
              contens={cardContent.map(({ title, content }) => (
                <Card key={title} title={title} paragraph={content} />
              ))}
            />
          </div>
        </div>
      </div>
      <Freelance />
      <Footer />
    </div>
  );
};
