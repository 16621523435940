import React from 'react';

import './freelance.css';

export const Info = ({ imgSrc, title, infoContent }) => (
  <div className='info-container'>
    <img className='info-image' src={imgSrc} alt='' />
    <div className='into-title'>{title}</div>
    <div className='info-content'>{infoContent}</div>
  </div>
);
