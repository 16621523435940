import image1 from '../../assets/images/images1.png';
import image2 from '../../assets/images/images2.png';
import image3 from '../../assets/images/images3.png';
import image4 from '../../assets/images/images4.jpeg';
import image5 from '../../assets/images/images5.png';
import image6 from '../../assets/images/images6.png';

export const freelanceContent = [
  {
    title: 'Intuitive Interface',
    content:
      'Streamlined and easy-to-use interface that simplifies your workflow.',
    image: image6,
  },
  {
    title: 'AI Assisted',
    content:
      'Leverage the power of AI to create impressive designs effortlessly.',
    image: image1,
  },
  {
    title: 'Design Fast',
    content:
      'Accelerate your design process with automated style implementation.',
    image: image2,
  },
  {
    title: 'Inspiring Designs',
    content:
      'Unleash your imagination to create awe-inspiring designs with ease.',
    image: image3,
  },
  {
    title: 'Collaborative',
    content: 'Empower your teams with collaborative design capabilities.',
    image: image4,
  },
  {
    title: 'Robust Features',
    content:
      'Innovative and reliable features for a seamless design experience.',
    image: image5,
  },
];
