import React, { useState } from 'react';

import './header.css';

export const Header = () => {
  const [date, setDate] = useState('');

  useState(() => {
    setDate(
      new Date()
        .toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          separator: '.',
        })
        .replace(/\//g, '.')
    );
  });
  return (
    <div className='header-container'>
      <h1 className='text-lg'>Stylesheets.AI</h1>
      <span>{date}</span>
    </div>
  );
};
