import React from 'react';
import './footer.css'; // Make sure to create a Footer.css file for styling

export const Footer = () => (
  <footer className='footer'>
    <div className='footer-links'>
      <a href='/about'>About Us</a>
      <a href='/contact'>Contact</a>
      {/* <a href='/privacy-policy'>Privacy Policy</a>
      <a href='/terms-of-service'>Terms of Service</a> */}
    </div>
    <div className='footer-rights'>
      <p>© 2023 stylesheets.ai. All rights reserved.</p>
    </div>
  </footer>
);
