import React from 'react';

import './slider.css';

export const Slider = ({
  contens,
  currentContent,
  preButtonDisabled,
  handleClickPrev,
  nextButtonDisabled,
  handleClickNext,
}) => {
  return (
    <div className='slider-container'>
      <button
        disabled={preButtonDisabled}
        onClick={handleClickPrev}
        className='prebutton'
      >
        &#171;
      </button>
      <div className='slides'>{contens[currentContent]}</div>
      <button
        disabled={nextButtonDisabled}
        onClick={handleClickNext}
        className='nextbutton'
      >
        &#187;
      </button>
    </div>
  );
};
