export const cardContent = [
  {
    title: 'Revolutionizing Design',
    content:
      'Step into the future of design with stylesheets.ai, where innovation meets unprecedented creativity. We bring you a groundbreaking solution set to transform and elevate your design experience in Figma. Armed with the power of Artificial Intelligence, stylesheets.ai is here to redefine the boundaries of design, unleashing a realm of possibilities and precision like never before.',
  },
  {
    title: 'Our Mission',
    content:
      'Our journey is inspired by a vision to empower designers with a suite of cutting-edge AI tools that blend seamlessly with Figma. We are dedicated to enriching your design experience, making it more intuitive, fluid, and extraordinarily efficient. With stylesheets.ai, say goodbye to the tediousness and repetitive chores of the design process.',
  },
  {
    title: 'Smart, Agile, and Intuitive',
    content:
      'Imagine a tool ingrained with an intelligence that intuitively grasps your creative visions, assisting and automating with immaculate accuracy and agility. That’s what stylesheets.ai embodies - a reliable design companion that not just simplifies but also inspires, turning your design dreams into awe-inspiring realities.',
  },
  {
    title: 'Passion-Driven Innovation',
    content:
      'Our hearts beat for innovation, and every stride we take aims to push the frontiers of design possibilities. At stylesheets.ai, our commitment shines through our passion to provide you with tools that stand as a testament to groundbreaking ingenuity and creativity.',
  },
  {
    title: 'Let’s Explore Together!',
    content:
      'We invite you to explore the remarkable capabilities of stylesheets.ai. Join us in this exciting journey and discover how our transformative tools can redefine your design narratives in Figma. Let’s craft extraordinary, let’s design with stylesheets.ai!',
  },
];
