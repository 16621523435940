import React from 'react';
import { freelanceContent } from './freelanceContent';
import { Info } from './info';
import image from '../../assets/images/images7.jpeg';

import './freelance.css';

export const Freelance = () => {
  return (
    <div className='freelance-container'>
      <h3 className='freelance-title'>
        <span style={{ color: '#e21880', fontSize: '60px' }}>&#10077; </span>
        Customized AI Design Solutions For You{' '}
        <span style={{ color: '#e21880', fontSize: '60px' }}>&#10078;</span>
      </h3>
      <p className='freelance-text'>
        Our advanced features focus on automating and enhancing your design
        process, boosting your creative potential.
      </p>

      <div className='freelance-content'>
        <div className='freelance-info'>
          <img className='freelance-image' src={image} alt='' />
          <div className='text'>Web and Mobile Development</div>

          <div className='offer'>We offer Freelance Services </div>
          <p className='freelance-contact'>
            Call : 510-417-7347 | Email: mydesigns@stylesheets.ai
          </p>
          <p className='freelance-contact'>OR</p>
          <p className='freelance-contact'>
            Let's Connect (calendly) :
            <a
              href='https://calendly.com/webdevshekhar'
              target='_blank'
              rel='noreferrer'
              style={{
                color: 'darkblue',
                textDecoration: 'none',
                fontSize: '12px',
              }}
            >
              {''} CLICK HERE
            </a>{' '}
          </p>
        </div>
        <div className='freelance-more-info'>
          {freelanceContent.map(({ title, content, image }, index) => (
            <Info
              key={index}
              title={title}
              infoContent={content}
              imgSrc={image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
