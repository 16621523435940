import React, { Fragment, useState } from "react";

import OpenAI from "openai";

import { Loader } from "../loader/Loader";
import image from "../../assets/images/marketing-img.jpg";
import { cardContent } from "../card/cardContent";

import "./form.css";

export const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [htmlElement, setHtmlElement] = useState({
    tagName: "",
    description: "",
  });
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, // This is also the default, can be omitted
    dangerouslyAllowBrowser: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await openai.chat.completions
      .create({
        model: "gpt-4o",
        messages: [{ role: "user", content: searchTerm }],
      })
      .then((res) => {
        setHtmlElement({
          tagName: searchTerm,
          description: res.choices[0].message.content,
        });
      })
      .catch((error) => {
        setError("There is something wrong, while featching data!!!");
        console.error("An error occurred while fetching the data", error);
      });
    setIsLoading(false);
    setSearchTerm("");
  };

  const marketingDecription = cardContent[0].content;

  return (
    <Fragment>
      <div className="marketing-container">
        <div className="marketing-information">
          <div className="text1">Design. Simplified.</div>
          <div className="text2">Inspire. Create.</div>
          <div className="text1">Transform. Reality.</div>
          <p className="marketing-description">{marketingDecription}</p>
        </div>
        <div className="marketing-image-container">
          <div className="outer-image"></div>
          <img className="marketing-image" src={image} alt="" />
        </div>
      </div>
      <h2 className="form-title">What do you want to design today?</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder="Create design ..."
        />
        <button className="submit-button" disabled={!searchTerm} type="submit">
          &#187;
        </button>
      </form>
      {isLoading ? (
        <Loader />
      ) : (
        htmlElement && (
          <div className="text-container">
            <h2
              style={{
                color: "#48a4a5",
                fontSize: "50px",
                fontWeight: "800",
              }}
            >
              {htmlElement.tagName ? htmlElement.tagName + " ?" : ""}
            </h2>
            <p className="description">{htmlElement.description}</p>
          </div>
        )
      )}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </Fragment>
  );
};
